@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.content {
  $gap: 50px;

  .document-name {
    margin-right: 15px;
    margin-bottom: 50px;

    font-weight: 700;
    font-size: 24px;
  }

  .document-permissions-subheader,
  .document-image-subheader,
  .document-tags-subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;

    background-color: color(app-white);

    .title {
      color: color(marriott-primary);
      font-weight: 700;
      font-size: 18px;
    }
  }

  .document-permissions-wrapper {
    //
  }

  .document-image-wrapper {
    //
  }

  .document-tags-wrapper {
    //
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-right: -$gap;

    .grid-item {
      width: calc(calc(100% / 3) - #{$gap});
      margin-right: $gap;
      margin-bottom: $gap;

      @media (max-width: 1600px) {
        width: calc(calc(100% / 2) - #{$gap});
      }

      @media (max-width: 1300px) {
        width: calc(calc(100% / 1) - #{$gap});
        min-width: 300px;
      }

      .add-chapter-button {
        height: 100%;
      }
    }
  }
}
