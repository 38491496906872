@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.vendor-item {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  background-color: color(app-bg);
  border: 1px solid color(marriott-light-grey-2);
  border-radius: $main-border-radius;

  .progress-indicator-wrapper {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .delete-button {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    color: color(marriott-orange);
    font-size: 24px;
  }

  .lock-wrapper {
    position: absolute;
    top: -14px;
    right: -14px;
    z-index: 1;
  }

  .item-body {
    padding: 20px 15px;

    .warning {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .icon-wrapper {
        display: flex;
        align-items: center;
        margin-right: 8px;

        svg {
          fill: color(marriott-light-grey);
        }
      }

      .message {
        height: 100%;
        padding-top: 3px;

        color: color(marriott-light-grey);
        font-weight: 700;
        font-size: 12px;
      }
    }

    .item-title {
      margin-bottom: 15px;

      color: color(marriott-primary);
      font-weight: 700;
      font-size: 12px;
    }

    .body-title {
      display: flex;
      justify-content: space-between;

      span {
        color: color(marriott-light-grey);
        font-size: 12px;

        &.name {
          font-style: italic;
        }

        &:first-child {
          margin-right: 10px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &.locked {
    background-color: color(app-white);
    border: 1px solid color(marriott-light-grey);

    .item-body {
      .item-title {
        color: color(marriott-light-grey);
      }
    }
  }

  &.is-processing {
    .item-body {
      opacity: 0.2;
    }

    .delete-button {
      color: color(marriott-light-grey);

      pointer-events: none;
    }
  }
}
