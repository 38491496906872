@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.variant-radio {
  $main-color: color(app-green);
  $secondary-color: color(marriott-light-grey-2);

  width: 100%;

  label {
    display: block;
    width: 100%;
    height: 100%;

    cursor: pointer;

    .wrapper {
      display: flex;
      flex-direction: column;
      padding: 10px;

      border: 1px solid $secondary-color;
      border-radius: $main-border-radius;

      &.is-default {
        padding: 0;
      }

      .header {
        display: flex;
        align-items: center;
        padding: 15px;

        border-radius: $main-border-radius;

        .dot {
          display: flex;
          margin-right: 20px;

          .dot-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;

            background-color: color(app-white);
            border: 1px solid $secondary-color;
            border-radius: 50%;

            .inner-dot {
              width: 10px;
              height: 10px;

              background-color: transparent;
              border-radius: 50%;
            }
          }
        }

        .default {
          display: flex;
          align-items: center;
          margin-right: 10px;

          color: color(marriott-orange);
          font-weight: 700;
          font-size: 12px;
        }

        .title {
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;

          font-weight: 700;
          font-size: 14px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        padding: 0 5px;

        .info {
          //

          .info-item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-size: 12px;

            &:not(:last-child) {
              margin-bottom: 10px;
            }

            .info-title {
              margin-right: 10px;

              color: color(marriott-light-grey);
              font-weight: 700;
            }

            .info-value {
              //
            }
          }
        }
      }
    }

    &:hover {
      .wrapper {
        border: 1px solid $main-color;

        .header .dot .dot-wrapper {
          border: 1px solid $main-color;
        }
      }
    }

    input[type="radio"]:checked + .wrapper {
      border: 1px solid $main-color;

      .header {
        background-color: $main-color;

        .dot .dot-wrapper {
          border: 1px solid transparent;

          .inner-dot {
            background-color: color(marriott-dark-grey);
          }
        }

        .default {
          color: color(app-white);
        }

        .title {
          color: color(app-white);
        }
      }

      &.is-default {
        border: 1px solid transparent;
      }
    }
  }
}
