@import "@STYLES/colors.scss";

.chapter-tags {
  padding-top: 50px;
  padding-bottom: 50px;

  background-color: color(app-white);

  .tags-list {
    $gap: 25px;

    display: flex;
    flex-wrap: wrap;
    margin-right: -$gap;
    margin-bottom: 15px;

    .tag-item-wrapper {
      width: calc(calc(100% / 6) - #{$gap});
      margin-right: $gap;
      margin-bottom: $gap;

      @media (max-width: 1600px) {
        width: calc(calc(100% / 5) - #{$gap});
      }

      @media (max-width: 1400px) {
        width: calc(calc(100% / 4) - #{$gap});
      }

      @media (max-width: 1200px) {
        width: calc(calc(100% / 3) - #{$gap});
      }

      @media (max-width: 1000px) {
        width: calc(calc(100% / 2) - #{$gap});
      }
    }
  }

  .no-tags {
    margin-bottom: 40px;

    color: color(marriott-light-grey);
    font-size: 14px;
  }
}
