@import "@STYLES/colors.scss";

.drop-file-input {
  width: 100%;
  height: 100%;

  .input {
    //

    .title {
      color: color(marriott-light-grey);
      font-size: 14px;
    }
  }
}
