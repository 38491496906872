@import "@STYLES/colors.scss";

.document-sidebar {
  $main-color: color(marriott-primary);
  position: relative;

  width: 100%;
  padding: 25px 25px 25px 0;

  background-color: color(app-white);

  .documents-title {
    margin-bottom: 30px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 16px;
  }

  .documents-list {
    //

    .document-list-item {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
