@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";
@import "@STYLES/mixins.scss";

.wide-button {
  position: fixed;
  bottom: 0;
  z-index: 5;

  width: 100%;
  height: $wide-button-height;

  background-color: color(app-green);

  &.with-sidebar {
    left: 350px;

    width: calc(100% - 350px);

    @media (max-width: 1200px) {
      left: 300px;

      width: calc(100% - 300px);
    }
  }

  button {
    color: color(app-white);
    font-weight: bold;
    font-size: 18px;

    @include hover-highlight(0.1);
  }
}
