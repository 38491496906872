@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.tag-item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;

  background-color: color(app-bg);
  border: 1px solid color(marriott-light-grey-2);
  border-radius: $main-border-radius;

  .tag-name {
    font-size: 12px;
  }

  .progress-indicator-wrapper {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .lock-wrapper {
    position: absolute;
    top: -14px;
    right: -14px;
    z-index: 1;
  }

  .delete-button {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    color: color(marriott-orange);
    font-size: 24px;
  }

  &.locked {
    background-color: color(app-white);
    border: 1px solid color(marriott-light-grey);

    .tag-name {
      color: color(marriott-light-grey);
    }
  }

  &.is-processing {
    .tag-name {
      opacity: 0.2;
    }

    .delete-button {
      color: color(marriott-light-grey);

      pointer-events: none;
    }
  }
}
