@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.document-permissions {
  padding-top: 50px;
  padding-bottom: 50px;

  .permissions-wrapper {
    padding: 25px;

    background-color: color(app-white);
    border: 1px solid color(marriott-light-grey-2);
    border-radius: $main-border-radius;

    .controls-wrapper {
      margin-bottom: 25px;
    }

    .users-list {
      //

      .user {
        position: relative;

        display: flex;

        border: 1px solid color(marriott-light-grey-2);
        border-radius: $main-border-radius;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .delete-button {
          position: absolute;
          top: 5px;
          right: 5px;

          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;

          color: color(marriott-orange);
          font-size: 24px;
        }

        .user-info {
          width: 300px;
          padding: 20px;

          border-right: 1px solid color(marriott-light-grey-2);

          .user-info-title {
            margin-bottom: 30px;

            color: color(marriott-primary);
            font-weight: 700;
            font-size: 14px;
          }

          .data {
            //

            .data-item {
              display: flex;
              justify-content: space-between;

              &:not(:last-child) {
                margin-bottom: 20px;
              }

              .data-title {
                margin-right: 15px;

                color: color(marriott-light-grey);
                font-weight: 700;
                font-size: 12px;
              }

              .data-value {
                font-size: 12px;
              }
            }
          }
        }

        .permissions-title {
          margin-bottom: 30px;

          color: color(marriott-primary);
          font-weight: 700;
          font-size: 14px;
        }

        .permissions-list {
          padding: 20px;

          .permission-wrapper {
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .no-users {
      color: color(marriott-light-grey);
      font-size: 14px;
    }
  }
}
