@import "@STYLES/colors.scss";

.preview-sidebar {
  position: relative;

  width: 100%;
  min-height: 100%;
  padding: 25px 25px 25px 0;

  background-color: color(app-white);

  .chapters-title {
    margin-bottom: 30px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 16px;
  }

  .chapters-list {
    //

    .chapter-list-item {
      &:not(:last-child) {
        margin-bottom: 30px;
      }

      .sections-title {
        margin-bottom: 15px;
        margin-left: 20px;

        color: color(marriott-primary);
        font-weight: 700;
        font-size: 12px;
      }

      .link {
        margin-bottom: 15px;

        line-height: 150%;
        text-align: left;

        &:hover {
          color: color(marriott-primary);
          text-decoration: underline;
        }
      }

      .chapter {
        .link {
          color: color(marriott-primary);
          font-weight: 700;
          font-size: 14px;
        }
      }

      .introduction-text {
        .link {
          padding-left: 20px;

          font-size: 12px;
        }
      }

      .sections-list {
        .link {
          padding-left: 40px;

          font-size: 12px;
        }
      }
    }
  }
}
