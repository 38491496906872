@import "@STYLES/colors.scss";
@import "@STYLES/design.scss";

.main-header {
  width: 100%;
  height: $main-header-height;

  background-color: color(marriott-primary);

  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .title {
      //
    }

    .user-wrapper {
      display: flex;
      align-items: center;

      .user-name {
        color: color(app-white);
        font-size: 13px;
        white-space: nowrap;
      }

      .logout-button {
        $size: 34px;

        display: flex;
        align-items: center;
        justify-content: center;
        width: $size;
        height: $size;

        margin-left: 15px;
        padding-left: 2px;

        background-color: rgb(255 255 255 / 10%);
        border-radius: 50%;

        &:hover {
          background-color: rgb(255 255 255 / 20%);
        }
      }
    }
  }
}
