@import "@STYLES/colors.scss";

.route-button {
  $main-color: color(marriott-primary);

  button {
    display: inline-flex;

    .title {
      margin-left: 10px;

      font-size: 12px;
      line-height: 150%;
      text-align: left;
    }

    .edit-icon {
      transform: translateY(-2px);
    }

    &:hover {
      .title {
        color: $main-color;
        text-decoration: underline;
      }

      .edit-icon {
        svg {
          fill: $main-color;
        }
      }
    }
  }

  &.active {
    button {
      cursor: default;

      .title {
        color: $main-color;
        text-decoration: none !important;
      }

      .edit-icon svg {
        fill: $main-color;
      }
    }
  }
}
