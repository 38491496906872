@import "@STYLES/colors.scss";

.floating-publish-button {
  position: sticky;
  z-index: 6;

  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
  margin-bottom: -41px;

  pointer-events: none;

  .publish-wrapper {
    height: 100%;
    margin-right: 50px;
    padding-left: 20px;

    border-left: 1px solid transparent;

    transition: margin-right 0.1s ease-in-out, border-color 0.2s ease-in-out;

    pointer-events: all;
  }

  &.to-right {
    .publish-wrapper {
      margin-right: 0;

      background-color: color(app-white);
      border-color: color(marriott-light-grey-2);
    }
  }
}
