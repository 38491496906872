@import "@STYLES/colors.scss";

.document-name {
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;

  background-color: color(app-white);

  .name-wrapper {
    display: flex;

    margin-bottom: 20px;

    .name {
      margin-right: 15px;

      font-weight: 700;
      font-size: 24px;
    }

    .preview-document {
      margin-left: 10px;
    }

    .edit-document-name,
    .preview-document {
      display: flex;
      align-items: center;

      border: 1px solid color(marriott-light-grey);
      border-radius: 50%;

      &:hover {
        border-color: color(marriott-primary);

        button svg {
          fill: color(marriott-primary);
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
      }
    }
  }

  .document-owner {
    display: flex;
    align-items: center;

    font-size: 14px;

    .owner {
      margin-right: 10px;

      color: color(marriott-light-grey);
    }

    .name {
      color: color(marriott-primary);
      font-weight: 700;
    }
  }
}
