@import "@STYLES/colors.scss";

.chapters-view {
  margin-top: 15px;

  .chapters-title {
    margin-bottom: 30px;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 16px;
  }

  .chapters-list {
    //

    .chapter-list-item {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
