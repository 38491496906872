@import "@STYLES/colors.scss";

.base-text-sticky-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  background-color: color(app-white);

  .title-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    white-space: nowrap;

    .title {
      margin-right: 25px;

      color: color(marriott-primary);
      font-weight: 700;
      font-size: 18px;
    }

    .buttons {
      display: flex;
      align-items: center;
      height: 100%;

      .button-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 12px;

        border-left: 1px solid color(marriott-light-grey-2);

        &:last-child {
          border-right: 1px solid color(marriott-light-grey-2);
        }

        .button {
          //

          &:hover {
            svg {
              fill: color(marriott-primary);
            }
          }
        }
      }
    }
  }
}
