.variant-form {
  //

  .is-default-variant {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    .checkbox-title {
      margin-right: 15px;

      font-weight: 700;
      font-size: 14px;
    }
  }

  .selects {
    margin-bottom: 40px;

    .select-wrapper:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
