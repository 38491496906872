@import "@STYLES/colors.scss";
@import "@STYLES/pdf.scss";

.base-editor {
  position: absolute;

  visibility: hidden;

  &.show {
    position: relative;

    visibility: visible;
  }

  .landscape {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 2;

    color: color(marriott-primary);
    font-weight: 700;
    font-size: 12px;
    font-family: Arial, sans-serif;

    visibility: hidden;

    pointer-events: none;
  }

  .bounds {
    $color: rgb(232 82 61 / 80%);
    $border-width: 2px;

    position: absolute;
    top: 42px;
    left: 50%;
    z-index: 2;

    box-sizing: content-box;
    width: $pdf-page-height + 50px;
    height: $pdf-page-width;

    border-bottom: $border-width dashed $color;
    transform: translateX(-50%);
    visibility: hidden;

    pointer-events: none;
  }
}

.editor-loading {
  display: flex;
  flex: 1;
  height: 700px;
  padding: 100px 0;
}
